var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"pa-3",attrs:{"align":"center","justify":"center"}},[_c('v-card',{staticClass:"mb-3",attrs:{"align":"left","justify":"left"}},[_c('v-card-text',{staticClass:"pa-3 font-weight-light white black--text"},[_c('v-icon',{staticClass:"primary--text lighten-2 pa-2"},[_vm._v(" mdi-application-edit-outline ")]),_vm._v("Agrupadores > Gerenciar Agrupadores do sistema ")],1)],1),_c('v-card',{staticClass:"pa-3 mb-4",nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.aplicarFiltros.apply(null, arguments)}}},[_c('v-row',{staticClass:"pa-3",class:_vm.isExtraSmall
                        ? 'd-flex justify-center'
                        : 'd-flex align-center justify-space-between'},[_c('v-btn',{attrs:{"color":"green white--text","data-cy":"criarAgrupador"},on:{"click":_vm.criarAgrupador}},[_vm._v(" Criar novo agrupador "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("add")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 pa-2 white--text",style:('background-color: ' +
                                    _vm.$vuetify.theme.themes.light.primary),attrs:{"x-small":"","elevation":"0","fab":"","loading":_vm.loadingInicial},on:{"click":function($event){return _vm.checarAgrupadores()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-sync ")])],1)]}}])},[_c('span',[_vm._v("Ressincronizar agrupadores")])])],1)],1),_c('v-data-table',{staticClass:"elevation-1 pa-3",class:_vm.isExtraSmall ? 'alinhar-botao-editar' : '',attrs:{"headers":_vm.colunasAgrupadores,"items":_vm.agrupadores,"loading":_vm.loadingInicial,"loading-text":"Carregando Agrupadores...","item-key":"agrupadores","footer-props":{
                itemsPerPageOptions: [10, 20, 50, -1],
                itemsPerPageText: 'Agrupadores por página:'
            },"data-cy":"tabelaAgrupadores"},scopedSlots:_vm._u([{key:"item.action",fn:function(props){return [_c('v-btn',{staticClass:"grey white--text",class:!_vm.isExtraSmall ? '' : 'mb-2 mr-3',attrs:{"elevation":"0","loading":_vm.loadingDialog,"data-cy":`editarAgrupador${props.index}`},on:{"click":function($event){return _vm.exibirDetalhe(props.item)}}},[_c('v-icon',{staticClass:"mr-2",style:({ color: 'white' }),attrs:{"medium":""},on:{"click":function($event){return _vm.exibirDetalhe(props.item)}}},[_vm._v("edit")]),_vm._v("Editar ")],1)]}}])})],1),(_vm.mostrarSnackbar)?_c('Snackbar',{attrs:{"mostrarSnackbar":_vm.mostrarSnackbar,"corSnackbar":_vm.corSnackbar,"mensagemSnackbar":_vm.mensagemSnackbar},on:{"fecharSnackbar":function($event){_vm.mostrarSnackbar = false}}}):_vm._e(),(_vm.dialogModalAgrupador)?_c('ModalAgrupador',{attrs:{"dialogModalAgrupador":_vm.dialogModalAgrupador,"agrupador":_vm.agrupador},on:{"fecharModal":function($event){_vm.dialogModalAgrupador = false},"atualizarListagem":function($event){return _vm.atualizarListagem($event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }